import React from 'react';
import '../styles/login.css'; // Estilo específico para a tela de login

const Login: React.FC = () => {
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Login realizado'); // Simples mensagem para teste
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2>Login</h2>
                <div className="form-group">
                    <label htmlFor="email">E-mail</label>
                    <input type="email" id="email" placeholder="Digite seu e-mail" required />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Senha</label>
                    <input type="password" id="password" placeholder="Digite sua senha" required />
                </div>
                <button type="submit" className="login-form-button">Entrar</button>
            </form>
        </div>
    );
};

export default Login;
