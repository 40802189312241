import React from 'react';
import '../styles/SectionTwo.css';
import kaio from '../assets/kaio.png'; // Importando sua foto

const SectionTwo: React.FC = () => {
    return (
        <section className="section-two">
            <img src={kaio} alt="Minha foto" className="photo" />
            <div className="text">
                <blockquote>
                    "A melhor maneira de prever o futuro é criá-lo."
                </blockquote>
            </div>
        </section>
    );
};

export default SectionTwo;
