import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import SectionOne from './components/SectionOne';
import SectionTwo from './components/SectionTwo';
import Login from './pages/Login';

const App: React.FC = () => {
    return (
        <Router>
            <Header />
            <Routes>
                {/* Rota principal */}
                <Route
                    path="/"
                    element={
                        <main>
                            <SectionOne />
                            <SectionTwo />
                        </main>
                    }
                />
                {/* Rota de login */}
                <Route path="/login" element={<Login />} />
            </Routes>
        </Router>
    );
};

export default App;
