import React from 'react';
import '../styles/SectionOne.css';
import landscape from '../assets/landscape.png'; // Importando a imagem de paisagem

const SectionOne: React.FC = () => {
    return (
        <section className="section-one" style={{ backgroundImage: `url(${landscape})` }}>
            <div className="overlay">
                <h1>Bem-vindo ao meu site</h1>
                <h2>Um espaço para compartilhar minha jornada</h2>
                <p>
                    Descubra mais sobre minha carreira, projetos e paixões. Seja
                    bem-vindo(a)!
                </p>
            </div>
        </section>
    );
};

export default SectionOne;
