import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importa o hook para navegação
import '../styles/Header.css';

const Header: React.FC = () => {
    const navigate = useNavigate(); // Hook para navegar entre páginas

    return (
        <header className="header">
            <div className="logo">Kaio.</div>
            <button
                className="login-button"
                onClick={() => navigate('/login')} // Redireciona para a página de login
            >
                Entrar
            </button>
        </header>
    );
};

export default Header;
